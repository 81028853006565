<template>

  <v-app v-bg:b :class="fundo">
    <sidemenu v-if="display"/>
    <topmenu v-if="display" />
    <v-sheet v-bg:b v-if="display">
      <v-main v-bg:b>

        <v-container fluid class="mx-0 px-3" v-bg:b>
          <router-view v-if="display"/>
        </v-container>

        <v-footer height="30" :color="hexShades(cor.active.b,0.025)" :dark="isDark" outlined app>
          <v-col class="text-center p0 m0" cols="12">
            <img width="120px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
            <span v-c:P class="mt-n2 f-raleway fs-12pt fw-200"> - Sistema de apoio à integração curricular</span>
          </v-col>
        </v-footer>
      </v-main>
    </v-sheet>

    <v-row style="position: absolute; width: 100%; top: 0px; margin-top: 16%;" v-if="!display">
      <v-col cols="1" md="3" lg="4"></v-col>
      <v-col cols="10" md="6" lg="4">
        <div class="" v-if="login && !display">
          <v-text-field v-model="pass" type="password" class="py-0 my-0" label="Digite chave de acesso" solo></v-text-field>
          <v-btn block class="my-n3 py-0" :color="cor.active.S" @click="clickAcesso">
            <i v-c:I class="btn-icon-left me-2" v-i:ic#login#28></i>
            Acessar calendário
          </v-btn>
        </div>
        <div class="" v-if="!login && !display">
          <p class="text-center mb-0">
            <i v-i:duo#smiley-sad#60 v-c:S></i>
          </p>
          <p class="text-center mb-0" v-c:S>
            OPS!!!!
          </p>
          <p class="text-center mb-0 fw-300 fs-11pt" v-c:I>
            Link inválido
          </p>
        </div>
      </v-col>
    </v-col>
  </v-row>

</v-app>

</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import topmenu from "./top-menu.vue";
import sidemenu from "./side-menu.vue";
import { eventBus } from "@/main.js";
import moment from "moment";

export default {
  name: 'appcalendario',
  components: { dummy, topmenu, sidemenu },
  computed: {
  },
  data () {
    return {
      display: false,
      login: false,
      pass: "",
      passValid: "",
      fundo: "appFullScreen",
      route: "",
    }
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      this.build();
    }
  },
  created: function() {
    var self = this;
    this.log("Created");
  },
  beforeMount() {
    //this.build();
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.build();
  },
  methods: {

    build() {
      var self = this;
      //console.log(`At this point, vm.$el has not been created yet.`)
      //console.log("this.$route.params",this.$route.params);
      var routes = [ "professores", "alunos",	"VPA", "diretores", "coordenador" ];
      var password = [ "prof2021", "alunos2021",	"calendariovpa2021", "calendariodiretor2021", "coord2021" ];
      if(this.$route.params.id != undefined) {
        var id = routes.indexOf(this.$route.params.id);
        //console.log(id);
        if(id != -1) {
          //console.log("ok");
          self.login = true;
          self.passValid = password[id];
          self.routes = routes[id];
          self.$forceUpdate();
        }
      }

    },

    clickAcesso() {
      var self = this;
      //console.log(self.pass);
      if(self.pass != self.passValid) {
        this.toastD("Chave inválida!");
        return;
      }
      self.fundo = "";
      self.display = true;
      eventBus.$emit("initCalendar",self.routes);

      var updates = {};
      var newKey = rdb.ref('/calendarios/2021S2/backlogs/'+self.routes).push().key;
      updates["/calendarios/2021S2/backlogs/"+self.routes+"/"+newKey] = { createdAt: moment().format() };
      //console.log("updates",updates);
      rdb.ref().update(updates);

    }

  }
}
</script>

<style>
.appFullScreen {
  background: url('https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Ffundo-calendario.png?alt=media&token=b8976758-3276-4255-9431-7196deb9598e')
  no-repeat center center fixed !important;
  background-size: cover;
}
</style>
